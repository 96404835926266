
import Header from "./Header"
import { useLoginStore } from '../../store/zustend'
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const Layout = (props) => {

    const navigate = useNavigate()

    const { loginStore } = useLoginStore()
    

    useEffect(() => {
        if(!loginStore.auth){
           return navigate('/iniciar-sesion')
        }
    },[])


    return(
        <>
            <Header /> 
            <div className="principalshow d-flex justify-content-center">
                <img src="/img/logosafibc.png" width="750px" alt="logo" className="bg-imageprincipal" />
            </div>

            <div className="container-fluid pt-5  bg-linesimage" style={{'backgroundColor': '#f2f3f8'}}>
                <div className="row d-flex justify-content-center animation" >
                    {props.children}
                </div>
            </div>    
        </>
    )
}

export default Layout