import { Fragment } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Presentacion from "../Principal/Presentacion"
import Login from "../Home/Login"
import Layout from "../Home/Layout"
import Navegacion from "../Home/Navegacion"
import RegistrarAhorrador from "../Home/RegistrarAhorrador"
import RegistrarContrato from "../Home/RegistrarContrato"
import Contratos from "../Home/Contratos"
import Tyc from "../Home/Tyc"
import SeguimientoAbonos from "../Home/SeguimientoAbonos"
import CalcularIntereses from "../Home/CalcularIntereses"


const Rutas = () => {


    return(
        <Router>
            <Fragment>
                    <Routes>
                        <Route path="/" element={<Presentacion />}/>
                        <Route path="/iniciar-sesion" element={<Login />}/>          
                        <Route path="/home" element={<Layout><Navegacion /></Layout>}/> 
                        <Route path="/registrar-ahorrador" element={<Layout><RegistrarAhorrador /></Layout>}/> 
                        <Route path="/registrar-contrato" element={<Layout><RegistrarContrato /></Layout>}/> 
                        <Route path="/contratos" element={<Layout><Contratos /></Layout>}/> 
                        <Route path="/calculo-interes" element={<Layout><CalcularIntereses /></Layout>}/> 
                        <Route path="/tyc-ahorrador" element={<Tyc />}/> 
                        <Route path="/seguimiento-abonos/:ahorrador" element={<SeguimientoAbonos />}/> 
                        
                    </Routes>
                      
            </Fragment>
        </Router>
    )
    


}

export default Rutas