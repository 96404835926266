
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiSafibc from "../../axios/axios";
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { Box, Button, Modal, TextField } from "@mui/material";
import Swal from "sweetalert2";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    width: '35%'
  };


const Tyc = () => {

    const { search } = useLocation();
    const navigate = useNavigate()

    const [codigo, setCodigo] = useState('')
    const [cel, setCel] = useState('')
    const [ida, setIda] = useState('')
    const [spTyc, setSpTyc] = useState(false)
    const [mTyc, setMtyc] = useState(false)
    const [chbTyc, setChbTyc] = useState(false)
    

    const obtenerInfo = () => {

        const query = new URLSearchParams(search);
        const ahorrador = query.get("a");

        if(!ahorrador) return navigate('/pagina-no-encontrada')

        setIda(ahorrador)

        const buscar_info = async() => {

            try {

               
                        const buscando = await apiSafibc.get(`/tyc-info/${ahorrador}`,{
                            headers: {
                                'XP1-K3Y' : `S4f1Vc`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })
                        
        
                        setCodigo(buscando.data.codigo_acceso)
                        setCel(buscando.data.ultimos_cel)
                

            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al buscar información de ahorrador, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config) 
                setSpTyc(false)
            }

            

        }

        buscar_info()


    }

    useEffect(() => {
        obtenerInfo()
    },[])

    const aceptarTyc = () => {

        const aceptando = async() => {

            if(!chbTyc) return toast.warning('No se han leido y aceptado terminos y condiciones!!', toast_config)

            try {
                Swal.fire({
                    title: "Acepta terminos y condiciones?",
                    text: "Se aceptaran tyc",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Aceptar!"
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        setSpTyc(true)
        

                        const ahorrador = atob(ida)

                        const tyc = {
                            id_ahorrador: ahorrador,
                            codigo_acceso: codigo,
                            ultimos_cel: cel
                        }

                        const aceptar = await apiSafibc.post('/tyc-aceptado',tyc,{
                            headers: {
                                'XP1-K3Y' : `S4f1Vc`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        setSpTyc(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: aceptar.data.mensaje
                        }).then((result) => {
                            navigate('/')
                        })
                    }
                });
            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al buscar información de ahorrador, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        aceptando()

    }

    return(
        <> 
        <div className="bg-tyc d-flex align-items-center">
            <div className=" container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card shadow">
                            <div className="card-body">
                                <hr />
                                    <div className="row ">
                                        <div className="col-md-12 d-flex justify-content-center mb-3">
                                            <img src="/img/logosafibc.png" alt="logo" className="maxw" width={'50%'}/>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-end mb-3">
                                            <p onClick={() => setMtyc(true)} style={{'cursor' : 'pointer'}}>Leer Terminos y Condiciones</p>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center mb-2">
                                            <TextField disabled value={codigo} variant="standard" label="Codigo de Acceso" style={{'width':'50%'}}/>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center mb-3">
                                            <TextField disabled value={cel} variant="standard" label="Celular" style={{'width':'50%'}}/>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center mb-2">
                                            <Button disabled={spTyc} variant="contained" style={{'width':'50%'}} onClick={aceptarTyc}>
                                                Aceptar TyC
                                                {spTyc && <div className="spinner-border spinner-border-sm" role="status" id='spisLoad'>
                                                    <span className="sr-only"></span>
                                                </div>}
                                            </Button>
                                        </div>
                                    </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <ToastContainer 
                transition={Flip}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"/> 
        </div>



        <Modal
            open={mTyc}
            onClose={() => setMtyc(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        > 
            <Box sx={style}>
                <div className="container p-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card shadow">
                                <div className="card-body">
                                <p>Terminos y condiciones</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value={chbTyc} id="flexCheckDefault" onClick={() => setChbTyc(true)}/>
                                <label class="form-check-label" for="flexCheckDefault">
                                    He leido terminos y condiciones
                                </label>
                            </div> 
                                </div>
                            </div>
                             
                        </div>
                    </div>
                      
                </div>
                                           
            </Box>
        </Modal>                        
        </>
    )

}

export default Tyc