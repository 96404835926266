
import React from 'react';
import Header from './Header';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const Presentacion = () => {

    const navigation = (text, navHeigth) =>{
        const sectionRef = document.getElementById(text);
        if (sectionRef) {
            
            const componente = document.getElementById(text).offsetTop;

            window.scrollTo({
                top: componente - navHeigth,
                behavior: 'smooth',
            });
        }
   }

    return(
        <div>
            <Header eventHandler={navigation}/>

            <div className='container-fluid m-0 p-0 bg-presentacion' id='inicio'>
                <p className='text-white p-presentacion font-lato'>Salud Financiera</p>
            </div>

            <div className='container-fluid py-5 bg-light border-bottom'>
                <div className='container '>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-5 mb-2'>
                            <img src="/img/img1.jpg" alt="" className='img-fluid shadow rounded' />
                        </div>
                        <div className='col-md-5 d-flex align-items-center'>
                            <span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 bg-white border-bottom' id='acercade'>
                <div className='container '>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-12 mb-2'>
                            <h2 className='text-center fw-bold'>Acerca de</h2>
                        </div>
                        <div className='col-md-5 d-flex align-items-center text-end'>
                            <span>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </span>
                        </div>
                        <div className='col-md-5 mb-2'>
                            <img src="/img/acercade.jpeg" alt="" className='img-fluid shadow rounded' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 bg-light border-bottom' id='ahorros'>
                <div className='container '>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-10'>
                            <img src="/img/ahorros.png" alt="" className='img-fluid rounded' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-5 bg-white border-bottom' id='contacto'>
                <div className='container '>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-6'>
                            <div className='card border-0 shadow'>
                                <div className='card-body'>
                                    <h2 className='text-center fw-bold'>Contacto</h2>
                                    <form action="" className='row'>
                                        <div className='col-md-12 mb-2 '>
                                            <TextField id="outlined-basic" label="Nombre Completo" variant="standard" style={{'width' : '100%'}} required/>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <TextField id="outlined-basic" label="E-mail" variant="standard" style={{'width' : '100%'}} required/>
                                        </div>
                                        <div className='col-md-6 mb-2'>
                                            <TextField id="outlined-basic" label="Teléfono" variant="standard" style={{'width' : '100%'}} required/>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <TextField multiline rows={2} label="¿Cómo podemos ayudarte?" variant="standard" style={{'width' : '100%'}} required/>
                                        </div>
                                        <div className='col-md-12 mb-2 d-flex justify-content-center'>
                                            <Button variant="contained" style={{'width': '50%'}} endIcon={<SendIcon />}>Enviar</Button>
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>

            <div className='container-fluid' style={{'backgroundColor' : '#025a8c'}}>
                <div className='row'>
                    <div className='col-md-12 m-0 p-0'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53771.45561486374!2d-115.49149052497935!3d32.61375182522858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d7700ca877ddd3%3A0xd40033a0e5cdf59a!2sMexicali%2C%20Baja%20California!5e0!3m2!1sen!2smx!4v1694838748041!5m2!1sen!2smx" width="100%" height="450" style={{'border':'0'}} loading="lazy"></iframe>
                    </div>
                </div>
            </div>

            <div className='container-fluid py-4' style={{'backgroundColor' : '#025a8c'}}>
                <div className='row d-flex justify-content-center'>
                    <div className='col-md-8 text-center'>
                            <i className="fa-brands fa-facebook fa-2xl m-2 shadow text-light"></i>
                            <i className="fa-brands fa-instagram fa-2xl m-2 shadow text-light"></i>
                    </div>
                </div>
            </div>
            

        </div>
    )
}

export default Presentacion
