import { Fragment } from 'react';
import Presentacion from './componentes/Principal/Presentacion'
import Rutas from './componentes/Rutas/Rutas';

function App() {
  return (
    <Fragment>
      <Rutas/>
      
    </Fragment>
  );
}

export default App;
