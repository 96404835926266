
import React, { useEffect, useRef, useState } from 'react';
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiSafibc from '../../axios/axios';
import { useLoginStore } from '../../store/zustend';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    width: '75%'
  };

  const styleCi = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    width: '25%'
  };


const Contratos = () => {

    const { loginStore } = useLoginStore()
    const spContratos = useRef(null)

    const [ahorrador, setAhorrador] = useState('')
    const [ahorradores, setAhorradores] = useState([])
    const [ahorros, setAhorros] = useState([])
    const [mostrarPagosContrato, setMostrarPagosContrato] = useState(false)
    const [pagosContrato, setPagosContrato] = useState([])
    const navigate = useNavigate()
    const [estatusContrato, setEstatusConrtato] = useState('')
    const [mCantidadInicial, setMcantidadInicial] = useState(false)
    const [cantidadInicial, setCantidadInicial] = useState(0)
    const [cantidadInicialModif, setCantidadInicialModif] = useState(0)
    const [spModificarCantidadInicial, setSpModificarCantidadInicial] = useState(false)
    const [contratoCi, setContratoCi] = useState(0)

    const [paginaActual, setPaginaActual] = useState(1);
    const registrosPorPagina = 5;

    const [registrosPagination, setRegistrosPagination] = useState([])
    const indiceUltimoRegistro = paginaActual * registrosPorPagina;
    const indicePrimerRegistro = indiceUltimoRegistro - registrosPorPagina;

    const obtenerAhorradores = async() => {


        try {
            const obtener_ahorradores = await apiSafibc('/obtener-ahorradores',{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            setAhorradores(obtener_ahorradores.data)


        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al obtener ahorradores, volver a cargar pagina por favor!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }

        
    }


    const buscarAhorrosAhorrador = (e) => {
        e.preventDefault()
        setAhorros([])
        setAhorrador(e.target.value)

        spContratos.current.removeAttribute('hidden')
            const buscar_ahorros_ahorrador = async() => {
                setRegistrosPagination([])

                try {
                    const buscando_ahorros = await apiSafibc(`/contratos-ahorrador/${e.target.value}`,{
                        headers:{
                            'Authorization' : `Bearer ${loginStore.token}`,
                            'Accept': 'application/x-www-form-urlencoded',
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })

                    //formatear fecha de contrato comienzo
                    const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']

                    if(buscando_ahorros.data.length > 0){
                        buscando_ahorros.data.forEach(contrato => {
                            let d = new Date(contrato.fecha_comienza)
                            contrato.fecha_comienza_td = `${d.getDate().toString().padStart('2', '0')} DE ${meses[d.getMonth()]} DE ${d.getFullYear()}`
                        })
                    }

                    setAhorros(buscando_ahorros.data)
                    setRegistrosPagination(buscando_ahorros.data.slice(indicePrimerRegistro, indiceUltimoRegistro))

                    spContratos.current.setAttribute('hidden', 'hidden')
                    
                } catch (error) {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.mensaje){
                            msg_error = error.response.data.mensaje
                        }else{
                            msg_error = 'Ocurrió un error al buscar contrato, favor de volver a cargar!'
                        }
                    }
                    spContratos.current.setAttribute('hidden', 'hidden')
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                }

            }   

            buscar_ahorros_ahorrador()
        
    }

    useEffect(() => {
        obtenerAhorradores()
    },[])

    const buscarPagos = (contrato, estatus_contrato) => {
        

        const buscarPagosContrato = async() => {
            
            try {

                setEstatusConrtato(estatus_contrato)

                const jcontrato = {
                    id_contrato: contrato
                }
 
                const buscando = await apiSafibc.post('/buscar-pagos-contrato',jcontrato,{
                    headers:{
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })  
                
                const meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']

                //formateando fechas
                if(buscando.data.length > 0){
                    buscando.data.forEach(pago => {
                        let d = new Date(pago.fecha_pago)
                        pago.fecha_pago_th = `${d.getDate().toString().padStart('2', '0')}-${meses[d.getMonth()]}-${d.getFullYear()}`
                    })
                }
               
                setPagosContrato(buscando.data)
                setMostrarPagosContrato(true)

            } catch (error) {
                console.log(error)
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al buscar contrato, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                    
            }

            

        } 

        buscarPagosContrato()

    }

    const registrarPagoContrato = (pago, pagado) => {
        if(pagado){
            setMostrarPagosContrato(false)
            return toast.info('Este pago ya se encuentra registrado!', toast_config)
        }

        const registrarPago = async() => {

            try {
                setMostrarPagosContrato(false)
                Swal.fire({
                    title: "Esta seguro?",
                    text: "Se registrara abono!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, abonar!"
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        const cd = new Date()
                        
                        const registro_pago = {
                            id_registro: pago,
                            fecha_registro: `${cd.getFullYear()}-${cd.getMonth() + 1}-${cd.getDate()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`,
                            ahorro_pagado: true,
                            id_ahorrador: ahorrador
                        }
        
                        const registrando = await apiSafibc.put(`/registrar-pago-contrato`,registro_pago,{
                            headers:{
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })
        
                        setMostrarPagosContrato(false)
                        toast.success(registrando.data.mensaje, toast_config) 
                    }
                  })

                 

            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al buscar contrato, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)  
            }

            

        }

        registrarPago()


    }

    const cancelarContrato = (e, contrato, ahorrador) => {


        const cancelar_contrato = async() => {

            try {
                Swal.fire({
                    title: "Esta seguro?",
                    text: "Se cancelara contrato de ahorrador!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, cancelar!"
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                      
                        const cancelar = {
                            id_contrato: contrato,
                            id_ahorrador: ahorrador
                        }     

                        const cancelando = await apiSafibc.put('/cancelar-contrato',cancelar,{
                            headers:{
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: cancelando.data.mensaje
                        }).then(r => navigate('/home'))
        
                    }
                  }); 
            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al cancelar contrato, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)  
            }


        }

        cancelar_contrato()

           

    }


    const paginacionClick = (numeroPagina) => {
        setPaginaActual(numeroPagina);
        const nuevosRegistros = ahorros.slice(
            (numeroPagina - 1) * registrosPorPagina,
            numeroPagina * registrosPorPagina
        );
        setRegistrosPagination(nuevosRegistros);
    };

    const modificarCantidadInicial = () => {

        const modificar_cantidadi = async() => {

            try {
                setMcantidadInicial(false)
                Swal.fire({
                    title: "Esta seguro?",
                    text: "Se modificara cantidad inicial de contrato!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, modificar!"
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        setSpModificarCantidadInicial(true)
                      
                        const cantidad_inicial = {
                            id_contrato: contratoCi,
                            cantidad_inicial: cantidadInicialModif
                        }     

                        const modificando = await apiSafibc.put('/modificar-cantidad-inicial-contrato',cantidad_inicial,{
                            headers:{
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })
                        
                        setSpModificarCantidadInicial(false)
                       

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: modificando.data.mensaje
                        }).then(r => navigate('/home'))
        
                    }
                  }).catch((error) => {
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.mensaje){
                            msg_error = error.response.data.mensaje
                        }else{
                            msg_error = 'Ocurrió un error al modificar cantidad inicial de contrato, favor de volver a cargar!'
                        }
                    }

                    setSpModificarCantidadInicial(false)
                    toast.error(msg_error, toast_config)  
                  }); 

                
                
            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al modificar cantidad inicial de contrato, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                setSpModificarCantidadInicial(false)
                toast.error(msg_error, toast_config)  
            }

        }

        modificar_cantidadi()

    }

    return(
        <>
            <div className='container py-5 animation'>
            <div className='row d-flex justify-content-center'>
                <div className='col-md-10'>
                    <div className='card shadow'>
                        <div className='card-body'>
                            <h3>Contratos</h3>
                            <hr />
                            <div className='row'>
                                <div className='col-md-5'>
                                <FormControl variant="standard" sx={{minWidth: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Ahorrador</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={ahorrador}
                                        onChange={buscarAhorrosAhorrador}
                                        label="Ahorrador"
                                    >
                                        {ahorradores && ahorradores.map(ahorrador => (
                                            <MenuItem value={ahorrador.id_ahorrador} key={ahorrador.id_ahorrador}>{`${ahorrador.nombre} ${ahorrador.paterno} ${ahorrador.materno}`}</MenuItem>
                                        ))} 
                                    </Select>
                                </FormControl>
                                </div>
                            </div>
                            <br />

                            <div id='spContratos' className='text-center' hidden ref={spContratos}>
                                <div className="spinner-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> &nbsp;
                                <div className="spinner-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> &nbsp;
                                <div className="spinner-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            
                                            
                            {ahorros && 
                                <div className='table-responsive'>
                                    <table className="table table-hover">
                                        <thead className='thead-light'>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Fecha Contrato</th>
                                                <th scope='col'>Cantidad Inicial</th>
                                                <th scope="col">Cantidad Ahorrando</th>
                                                <th scope="col">Ahorro Actual</th>                                            
                                                <th scope="col">Estatus</th>
                                                <th scope="col">Pagos</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {registrosPagination.map((ahorro, i) => (
                                                <tr key={ahorro.id_contrato}>
                                                    <td className='fw-bold'>{i + 1}</td>
                                                    <td>{ahorro.fecha_comienza_td}</td>
                                                    <td >
                                                        <span data-toggle="tooltip" 
                                                                data-placement="top" 
                                                                title="Modificar" 
                                                                style={{'cursor' : 'pointer'}} 
                                                                onClick={() => {
                                                                    setContratoCi(ahorro.id_contrato)
                                                                    setCantidadInicial(ahorro.cantidad_inicial) 
                                                                    setMcantidadInicial(true)
                                                                }}>
                                                                    ${ahorro.cantidad_inicial}.00
                                                        </span>
                                                    </td>
                                                    <td>${ahorro.cantidad_ahorrar}.00</td>
                                                    <td>${ahorro.sum}.00</td>
                                                    <td className={ahorro.estatus === 'ACTIVO' ? 'text-primary' : ahorro.estatus === 'CANCELADO' ? 'text-danger' : ahorro.estatus === 'TERMINADO' ? 'text-primary' : null}>
                                                        {ahorro.estatus}
                                                    </td>
                                                    <td><Button variant='outlined' onClick={() => buscarPagos(ahorro.id_contrato, ahorro.estatus)}>Ver</Button></td>
                                                    <td>
                                                        {ahorro.estatus === 'CANCELADO' ?
                                                            <DeleteOutlineIcon/>
                                                        :
                                                            <DeleteOutlineIcon className='text-danger' style={{'cursor': 'pointer'}} onClick={(e) => cancelarContrato(e, ahorro.id_contrato, ahorro.id_ahorrador)}/>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Pagination>
                                    {[...Array(Math.ceil(ahorros.length / registrosPorPagina))].map((_, index) => (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === paginaActual}
                                        onClick={() => paginacionClick(index + 1)}
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                    ))}
                                    </Pagination>
                                </div>
                            }


                            <Modal
                                open={mostrarPagosContrato}
                                onClose={() => setMostrarPagosContrato(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                > 
                                    <Box sx={style}>
                                        <div className='nav-header2 pt-4'></div>
                                        <div className='card'>
                                            <div className='card-header text-center'>
                                                <h3>Pagos de Contrato</h3>
                                            </div>
                                            <div className='card-body'>
                                                {pagosContrato &&
                                                    <div className='table-responsive'>
                                                    <table className='table'>
                                                        <thead >
                                                            <tr>
                                                                {pagosContrato.map(fp => (
                                                                    <th key={fp.fecha_pago} scope="col" className='text-center' style={{'whiteSpace': 'nowrap'}}>{fp.fecha_pago_th}</th>
                                                                ))}
                                                                   
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {pagosContrato.map(pago => (
                                                                    <td 
                                                                        key={pago.id_registro}
                                                                        onClick={() => {
                                                                           if(estatusContrato === 'CANCELADO') {
                                                                                setMostrarPagosContrato(false)
                                                                                return toast.info('Este contrato ya se encuentra cancelado, no se pueden realizar abonos!', toast_config)
                                                                            }
                                                                            registrarPagoContrato(pago.id_registro, pago.ahorro_pagado ? true : false, pago.fecha_pago)
                                                                        }} 
                                                                        style={estatusContrato === 'CANCELADO' ? null : estatusContrato === 'ACTIVO' ? {'cursor' : 'pointer'} : null}
                                                                        className={pago.ahorro_pagado ? 'bg-success text-light' : 'bg-danger pagos-hover text-light'}
                                                                        >
                                                                            $ {pago.cantidad}.00
                                                                    </td>
                                                                ))}
                                                                    
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                }
                                                    
                                            </div>
                                        </div>
                                    </Box>
                            </Modal>

                            <Modal
                                open={mCantidadInicial}
                                onClose={() => setMcantidadInicial(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                >
                                    <Box sx={styleCi}>
                                        <div className='nav-header2 pt-4'></div>
                                        <div className='card'>
                                            <div className='card-header text-center'>
                                                <h3>Modificar Cantidad Inicial</h3>
                                            </div>
                                            <div className='card-body'>
                                                <div className='row'>
                                                    <div className='col-md-12 d-flex justify-content-center mb-2'>
                                                        <TextField disabled value={`$${cantidadInicial}.00`} variant='standard' label='Cantidad Inicial Actual' style={{'width':'50%'}}/>
                                                    </div>
                                                    <div className='col-md-12 d-flex justify-content-center mb-2'>
                                                        <TextField type='number' value={cantidadInicialModif} onChange={(e) => setCantidadInicialModif(e.target.value)} variant='standard' label='Cantidad Modificar' style={{'width':'50%'}}/>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <Button disabled={spModificarCantidadInicial} variant='contained' onClick={modificarCantidadInicial}>
                                                            Modificar
                                                            {spModificarCantidadInicial &&
                                                                <div className="spinner-border spinner-border-sm" role="status" hidden id='spisLoad'>
                                                                    <span className="sr-only"></span>
                                                                </div>
                                                            }
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>

                            </Modal>
                            
                            
                            <hr />

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer 
                transition={Flip}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"/>
        </div>
        </>
    )

}

export default Contratos