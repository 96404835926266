
import { Button, Input } from "@mui/material"
import { useNavigate } from "react-router-dom"
import apiSafibc from "../../axios/axios"
import { useEffect, useRef, useState } from "react"
import { useLoginStore } from '../../store/zustend'
import jwtDecode from "jwt-decode"
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";

const Login = () => {

    //declaro para guardar la autenticacin del token usando zustand
    const { guardarAuth, loginStore } = useLoginStore()

    const spIniciarSesion = useRef(null)

    const [ credenciales, guardarCredenciales] = useState({})
    const [inputUsuario, setInputUsr] = useState('')
    
    const navigate = useNavigate()

    useEffect(() => {
        if(loginStore.auth) return navigate('/home')
    }, [])


    const iniciarSesion = async(e) => {
        e.preventDefault()
        
        spIniciarSesion.current.removeAttribute('hidden')

        await apiSafibc.post('/iniciar-sesion',credenciales,
            {
                headers: {
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-4P1-K3Y' : 'Fg3S1sT3m4s4dm0n.!'
                }
            })
            .then((result) => {

                const { token } = result.data
                spIniciarSesion.current.setAttribute('hidden', 'hidden')
                localStorage.setItem('token', token)
                const usr = jwtDecode(token)
    
                //colocarlo token en el state de zustand
                guardarAuth({
                    auth: true,
                    token: token,
                    jtoken: usr
                })
                
                //redireccionar
                navigate('/home')
                
            }).catch((error) => {
                const msg_error = error.response ? error.response.data.mensaje : 'Ocurrió un error al iniciar sesion'
                //mensaje de toastify
                toast.error(msg_error, toast_config)
                spIniciarSesion.current.setAttribute('hidden', 'hidden')
            });       

    }

    const leerDatos = (e) => {
        if(e.target.name === 'usr'){
            const uppercaseValue = e.target.value.toUpperCase();
            setInputUsr(uppercaseValue);
        }
        guardarCredenciales({
            ...credenciales,
            [e.target.name] : e.target.value
        })
    }


    return(
        <div className="bg-login d-flex align-items-center">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6">
                        <div className="card-transparent shadow-lg border-0 card-slide">
                            <div className="card-body m-0 p-0 shadow-lg">
                                <form className="row m-0 p-0 rounded shadow-lg" >
                                    <div className="col-md-4 bg-logologin rounded-start d-flex align-items-center">
                                        <img src="/img/logosafibc.png" alt="logo" className="maxw"/>
                                    </div>
                                    <div className="col-md-8 bg-logologinsesion rounded-end">
                                        <Input onChange={leerDatos} name='usr' className="my-2" placeholder="Usuario" style={{'width' : '100%'}} />
                                        <Input type="password" onChange={leerDatos} name='pass' className="my-2" placeholder="********" style={{'width' : '100%'}} />
                                        <Button onClick={iniciarSesion} variant="outlined" className="my-2" style={{'width' : '100%'}}>
                                            Log In

                                            <div className="spinner-border spinner-border-sm" role="status" hidden id='spisLoad' ref={spIniciarSesion}>
                                                <span className="sr-only"></span>
                                            </div>

                                        </Button>     
                                        
                                    </div>
                                </form>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer position="bottom-center"
                    transition={Flip}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                
            </div>
        </div>
    )
}

export default Login