
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import apiSafibc from "../../axios/axios"
import { useLoginStore } from "../../store/zustend"

import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { useEffect, useState } from "react";

const Navegacion = () => {

    const { loginStore } = useLoginStore()

    const [modulos, setModulos] = useState([])

    const modulosUsuario = async() => {


        try {
            const usrb64 = btoa(loginStore.jtoken.usuario)

            const modulos_usuario = await apiSafibc(`/modulos-usuario/${usrb64}`,{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`
                }
            })

            setModulos(modulos_usuario.data)


        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al buscar contrato, favor de volver a cargar!'
                }
            }
            
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }

    }

    useEffect(() => {
        modulosUsuario()
    },[])


    return(
        <div className="container animation">
            <div className="row d-flex justify-content-center">
            {
                modulos.map((modulo) => (
                    <div className="col-md-4 d-flex justify-content-center animation-layout" key={modulo.ruta_mod}>
                    <div className="card-transparent">
                        <div className="card-body text-center">
                            <Link to={modulo.ruta_mod}>
                                <img src={modulo.img_mod} alt="" width={'150px'} /><br/>
                            </Link>
                            <Link to={modulo.ruta_mod}>
                                <Button variant="contained">{modulo.boton}</Button>
                            </Link>
                        </div>
                    </div>
                    </div>
                ))
                
            }
            </div> 
         
            
            <ToastContainer position="bottom-center"
                transition={Flip}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div> 
        

    )
}

export default Navegacion