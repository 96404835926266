
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiSafibc from '../../axios/axios';
import { useLoginStore } from '../../store/zustend';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const RegistrarAhorrador = () => {

    const { loginStore } = useLoginStore()

    const [datos, setDatos] = useState({})
    const [catSexo, setCatSexo] = useState([])
    const [sexo, setSexo] = useState('')
    const [edad, setEdad] = useState('')
    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [spRA, setSpRA] = useState(false)

    const navigate = useNavigate()

    const catalogos = () => {

        const cargar = async() => {
            try {
                const cargando = await apiSafibc.get('/leer-cat-sexo',{
                    headers:{
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setCatSexo(cargando.data)


            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al buscar catalogos, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config) 
            }

            

        }

        cargar()

    }

    useEffect(() => {
        catalogos()
    },[])

    const leerDatos = (e) => {

        if(e.target.name === 'rfc'){
            if(e.target.value.length >= 10){
                const anio = e.target.value.substr(4,2)
                const mes = e.target.value.substr(6,2)
                const dia = e.target.value.substr(8,2)

                const anio_completo = parseInt(anio, 10) < 30 ? `20${anio}` : `19${anio}`
               
                const fn = `${anio_completo}-${mes.padStart('2', '0')}-${dia.padStart('2', '0')} 12:00:00`

                const fnd = new Date(fn)
    
                setFechaNacimiento(`${fnd.getFullYear()}-${(fnd.getMonth() + 1).toString().padStart('2', '0')}-${fnd.getDate().toString().padStart('2', '0')}`)

                const cd = new Date()
    
                /*const dif_mili = cd - fnd
                const dif_dias = dif_mili / (1000 * 60 * 60 * 24)*/

                let edad = cd.getFullYear() - fnd.getFullYear();
                const diferenciaMeses = cd.getMonth() - fnd.getMonth();

                // Restar un año si aún no ha cumplido años en este año
                if (diferenciaMeses < 0 || (diferenciaMeses === 0 && cd.getDate() < fnd.getDate())) {
                    edad--;
                }
                
                setEdad( `${edad} Años` )
            }
        }

        setDatos({
            ...datos,
            [e.target.name] : typeof e.target.value === 'string' ? e.target.value.toUpperCase() : e.target.value
        })
    }

    const guardarAhorrador = async(e) => {
        e.preventDefault()
        datos.id_sexo = sexo
        datos.fecha_nacimiento = fechaNacimiento
        let key = ''
        if(!datos.nombre) key = 'nombre'
        if(!datos.paterno) key = 'paterno'
        if(!datos.materno) key = 'materno'
        if(!datos.correo) key = 'correo'
        if(!datos.cel) key = 'cel'
        if(!datos.rfc) key = 'rfc'
        if(!datos.homoclave) key = 'homoclave'
        if(!datos.fecha_nacimiento) key = 'fecha_nacimiento'
        if(!datos.id_sexo) key = 'sexo'

        if(key) return toast.info(`El campo ${key} es necesario!`,toast_config)

        try {
            setSpRA(true)
            
            const guardar_ahorrador = await apiSafibc.post('/guardar-ahorrador',datos,{
                headers: {
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            
            setSpRA(false)

            Swal.fire({
                icon:'success',
                title: 'Enhorabuena!',
                text: guardar_ahorrador.data.mensaje
            })
            .then((result) => {
                navigate('/home')
            })


        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al guardar ahorrador, favor de volver a cargar!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config) 
           setSpRA(false)
        }
    }

    return(
        <div className='container pt-5 animation'>
            <div className='row d-flex justify-content-center'>
                <div className='col-md-6'>
                    <div className='card shadow'>
                        <div className='card-body'>
                            <h3>Registrar Ahorrador</h3>
                            <hr />
                            <div className='row'>
                                <div className='col-md-4'>
                                    <TextField onChange={leerDatos} name='nombre' className="mb-2"  label="Nombre(s)" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-4'>
                                    <TextField onChange={leerDatos} name='paterno' className="mb-2" label="Apellido Paterno" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-4'>
                                    <TextField onChange={leerDatos} name='materno' className="mb-2" label="Apellido Materno" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-6'>
                                    <TextField onChange={leerDatos} name='correo' className="mb-2" label="Correo" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-6'>
                                    <TextField onChange={leerDatos} name='cel' className="mb-2" label="Celular" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-4'>
                                    <TextField onChange={leerDatos} name='rfc' className="mb-2" label="Rfc" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-2'>
                                    <TextField onChange={leerDatos} name='homoclave' className="mb-2" label="Homoclave" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-4'>
                                    <TextField type="date" value={fechaNacimiento} disabled name='fecha_nacimiento' className="mb-2" label="dd/mm/aaaa" variant="standard" style={{'width': '95%'}}/>
                                </div>
                                <div className='col-md-2'>
                                    <TextField  name='edad' value={edad} className="mb-2" label="Edad" variant="standard" style={{'width': '95%'}} disabled/>
                                </div>
                                <div className='col-md-3'>
                                    <FormControl variant="standard" sx={{minWidth: '100%' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Sexo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={sexo}
                                            onChange={(e) => setSexo(e.target.value)}
                                            label="Sexo"
                                        >
                                            {catSexo && catSexo.map(sexo => (
                                                <MenuItem value={sexo.id_sexo} key={sexo.id_sexo}>{sexo.sexo}</MenuItem>
                                            ))} 
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <Button disabled={spRA} onClick={guardarAhorrador} size="small" className="mt-3" label="Celular" variant="contained" style={{'width': '75%'}}>
                                        Guardar
                                        {spRA && <div className="spinner-border spinner-border-sm" role="status" id='spisLoad'>
                                            <span className="sr-only"></span>
                                        </div>}
                                    </Button>
                                </div>
                            </div>
                            <hr />

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer 
                transition={Flip}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"/>
        </div>
    )
}

export default RegistrarAhorrador