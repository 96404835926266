
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import { Button, TextField } from "@mui/material";
import apiSafibc from "../../axios/axios";
import { Table, Pagination } from 'react-bootstrap';

const RevisarPagos = () => {

    const [sesion, setSesion] = useState(false)
    const [login, setLogin] = useState(true)

    const [spSeguimientoAbonos, setSpSeguimientoAbonos] = useState(false)
    const [codigo, setCodigo] = useState('')
    const [cel, setCel] = useState('')
    const [abonos, setAbonos] = useState([])

    const { ahorrador } = useParams();

    const [paginaActual, setPaginaActual] = useState(1);
    const registrosPorPagina = 5;

    const [registrosPagination, setRegistrosPagination] = useState([])
    const indiceUltimoRegistro = paginaActual * registrosPorPagina;
    const indicePrimerRegistro = indiceUltimoRegistro - registrosPorPagina;
   

    const loginPagos = () => {

        const login_seguimiento = async() => {  

            const auth = {
                codigo, cel, ahorrador
            }

            try {
                setSpSeguimientoAbonos(true)

                const ahorros = await apiSafibc.post('/login-seguimiento-abonos', auth, {
                    headers:{
                        'XP1-K3Y' : `S4f1Vc`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                setSpSeguimientoAbonos(false)
                setLogin(false)

                const meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']

                ahorros.data.forEach(abono => {
                    const d = new Date(abono.fecha_pago)
                    abono.fecha_pago = `${d.getUTCDate().toString().padStart(2,'0')} DE ${meses[d.getUTCMonth()]} DE ${d.getUTCFullYear()}`
                })
                
                setAbonos(ahorros.data)
                setSesion(true)

                
                setRegistrosPagination(ahorros.data.slice(indicePrimerRegistro, indiceUltimoRegistro))
                //const registrosActuales = ahorros.data.slice(indicePrimerRegistro, indiceUltimoRegistro);
                

            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al guardar ahorrador, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config) 
                setSpSeguimientoAbonos(false)
            }

            

        }

        login_seguimiento()


    }

   

    const paginacionClick = (numeroPagina) => {
        setPaginaActual(numeroPagina);
        const nuevosRegistros = abonos.slice(
            (numeroPagina - 1) * registrosPorPagina,
            numeroPagina * registrosPorPagina
        );
        setRegistrosPagination(nuevosRegistros);
    };
    

    return(
        <>

        {login &&
            <div className="bg-sa d-flex align-items-center ">
            <div className=" container animation">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card-transparent border-0">
                            <div className="card-body">
                                    <div className="row ">
                                        <div className="col-md-12 d-flex justify-content-center mb-3">
                                            <img src="/img/logosafibc.png" alt="logo" className="maxw" width={'50%'}/>
                                        </div>
                                        <div className="col-md-12">
                                            <p className="text-center fw-bold">
                                                Ingrese el codigo de acceso enviado <br /> por mensaje y los ultimos 4 digitos <br /> del celular registrado en el contrato.
                                            </p>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center mb-2">
                                            <TextField value={codigo} onChange={(e) => setCodigo(e.target.value)} variant="standard" label="Codigo de Acceso" inputProps={{ maxLength: 8}} style={{'width':'50%'}}/>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center mb-3">
                                            <TextField value={cel} onChange={(e) => setCel(e.target.value)} variant="standard" label="Celular"  inputProps={{ maxLength: 4}} style={{'width':'50%'}}/>
                                        </div>
                                        <div className="col-md-12 d-flex justify-content-center mb-2">
                                            <Button disabled={spSeguimientoAbonos} variant="contained" style={{'width':'50%'}} onClick={loginPagos}>
                                                Aceptar
                                                {spSeguimientoAbonos && <div className="spinner-border spinner-border-sm" role="status" id='spisLoad'>
                                                    <span className="sr-only"></span>
                                                </div>}
                                            </Button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        }



        { sesion &&
        <>
            <div className="container-fluid">
                <div className="row">
                    <nav className="navbar nav-header1" >
                        <div className="row">
                            <div className="col-md-3 ms-5 py-2">
                                <Link to={'/'}>
                                    <img src="/img/logosafibc.png" width={'650'} alt="" className="maxw" />
                                </Link>
                            </div>
                        </div>    
                    </nav>
                    <nav className="navbar navbar-expand-md shadow nav-header2 pt-3">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> 
                
                    </nav>
                </div>
            </div>
            <div className="container-fluid py-5 bg-linesimage" style={{'backgroundColor': '#f2f3f8'}}>
                <div className="row d-flex justify-content-center animation" >
                    <div className="col-md-10">
                        <div className="card shadow-lg">
                            <div className="card-header"></div>
                            <div className="card-body border-0">
                                <hr />
                                <h2>Calendario de Pagos</h2>
                                <div className="table-responsive">
                                    <Table hover>
                                        <thead className="thead-light">
                                            <tr >
                                                <th scope="col">Fecha</th>
                                                <th scope="col">Cantidad</th>
                                                <th scope="col">Pagado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {abonos && 
                                                registrosPagination.map((abono) => (
                                                    <tr key={abono.fecha_pago}>
                                                        <td>{abono.fecha_pago}</td>
                                                        <td>${abono.cantidad}.00</td>
                                                        <td className={abono.ahorro_pagado === true ? 'bg-success text-light' : ''}>{abono.ahorro_pagado === true ? 'PAGADO' : 'PENDIENTE'}</td>
                                                    </tr>
                                                ))
                                                
                                            }
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        {[...Array(Math.ceil(abonos.length / registrosPorPagina))].map((_, index) => (
                                        <Pagination.Item
                                            key={index + 1}
                                            active={index + 1 === paginaActual}
                                            onClick={() => paginacionClick(index + 1)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                        ))}
                                    </Pagination>
                                </div>
                                <hr />
                                

                            </div>
                            <div className="card-footer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }   

            <ToastContainer 
                transition={Flip}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"/> 
        </>
    )

}

export default RevisarPagos