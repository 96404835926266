
import { Button, InputLabel, MenuItem, Select, FormControl } from "@mui/material"
import { useEffect, useState } from "react"
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiSafibc from "../../axios/axios";
import { useLoginStore } from "../../store/zustend";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const CalcularIntereses = () => {

    const { loginStore } = useLoginStore()

    const [fi, setFi] = useState('')
    const [ff, setFf] = useState('')
    const [tipoAhorros, setTipoAhorros] = useState([])
    const [tipoAhorro, setTipoAhorro] = useState('')
    const [fechasPermitidas, setFechasPermitidas] = useState([]);
    const [bFcontratos, setBfcontratos] = useState(true)

    const [spGenerarExcel, setSpGenerarExcel] = useState(false)

    const navigate = useNavigate()

    const generarExcel = () => {

        const generando = async() => {

            try {
                /*if(!fi) setFi('1900-01-01')
                if(!ff) setFf('2099-12-31')*/
                if(!tipoAhorro) return toast.info('Es necesario el tipo de contrato', toast_config)
                if(!selectedDate) return toast.info('Es seleccionar el inicio del contrato', toast_config)

                setSpGenerarExcel(true)

                const fechas = {
                    fi: selectedDate,
                    tipoAhorro
                }

                const generar = await apiSafibc.post('/calcular-intereses-excel',fechas,{
                    headers:{
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                let byteCharacters = atob(generar.data.b64file);
                let byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);

                let file = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                let fileURL = URL.createObjectURL(file);

                const cd = new Date()

                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `Reporte ${cd.getFullYear()}${(cd.getMonth()+1).toString().padStart(2, '0')}${cd.getDate().toString().padStart(2, '0')}`;
                link.click();
                URL.revokeObjectURL(fileURL);

                Swal.fire({
                    icon: 'success',
                    title: 'Enhorabuena!',
                    text: generar.data.mensaje
                }).then(r => {
                    navigate('/home')
                })

            } catch (error) {
                console.log(error)
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al guardar ahorrador, favor de volver a cargar!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config) 
                setSpGenerarExcel(false)
            }

        }

        generando()


    }

    const obtenerTipoAhorros = async() => {

        try {
            const obtener_tipo_ahorros = await apiSafibc('/obtener-tipo-ahorros',{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            setTipoAhorros(obtener_tipo_ahorros.data)
        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al obtener tipo de ahorros, volver a cargar pagina por favor!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }
    }

    useEffect(() => {
        obtenerTipoAhorros()
        //iniciosContratos()
    }, [])

    const leerFechasContratos = async(e) => {

        setTipoAhorro(e.target.value)

        iniciosContratos(e.target.value)


    }


    const iniciosContratos = (contrato) => {

        const fechasContratos = async() => {

            try {

                const fechas = await apiSafibc.get(`/fechas-inicio-contratos/${contrato}`,{
                    headers:{
                        'Authorization' : `Bearer ${loginStore.token}`,
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                })

                const fp = []
                
                if(fechas.data.length > 0) {

                    const fec = fechas.data

                    fec.forEach((f) => {
                        fp.push(new Date(f.fecha_comienza))
                    })

                    setFechasPermitidas(fp)
                    setBfcontratos(false)
                }else{
                    toast.info('No se encontraron fechas de este tipo de contrato!', toast_config)
                    setFechasPermitidas([])
                    setBfcontratos(true)
                }

                
                
            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al obtener tipo de ahorros, volver a cargar pagina por favor!'
                    }
                }
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        fechasContratos()

    }


    
      // Estado para almacenar la fecha seleccionada
      const [selectedDate, setSelectedDate] = useState(null);
    
      // Función para manejar cambios de fecha
      const handleDateChange = (date) => {
        setSelectedDate(date);
      };


    return(
        <>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                        <div className="card shadow-lg border-0">
                            <div className="card-body">
                                <div className="row">
                                    <h2>Reporte de Calculo de Interes</h2>
                                    
                                    <div className="col-md-6 mb-3 d-flex align-items-center">
                                        <FormControl variant="standard" sx={{minWidth: '100%' }}>
                                            <InputLabel id="standard-label">Tipo de Ahorro</InputLabel>
                                            <Select
                                                labelId="standard-label"
                                                id="select-standard"
                                                value={tipoAhorro}
                                                onChange={leerFechasContratos}
                                                label="Tipo de Ahorro"
                                            >
                                                <MenuItem value={'0'} key={0}>CATORCENAL</MenuItem>
                                                <MenuItem value={'1'} key={1}>QUINCENAL</MenuItem>
                                                <MenuItem value={'2'} key={2}>MENSUAL</MenuItem>
                                                {/*tipoAhorros && tipoAhorros.map(ta => (
                                                    <MenuItem value={ta.id_ahorro} key={ta.id_ahorro}>{`${ta.descripcion}`}</MenuItem>
                                                ))*/} 
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-12 my-3 d-flex align-items-center">
                                        {/*<input value={fi} onChange={(e) => setFi(e.target.value)} name="fi" type="date" className="form-control" />*/}
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            includeDates={fechasPermitidas}
                                            className="form-control"
                                            placeholderText="Selecciona una fecha"
                                            disabled={bFcontratos}
                                        />
                                    </div>
                                    <div>
                                        <Button disabled={spGenerarExcel} variant="contained" onClick={generarExcel}>
                                            Generar
                                            {spGenerarExcel && <div className="spinner-border spinner-border-sm" role="status" id='spisLoad'>
                                                <span className="sr-only"></span>
                                            </div>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer 
                transition={Flip}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"/> 
            </div>
        </>
    )

}

export default CalcularIntereses