
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast_config } from "../../toastify/config";
import apiSafibc from '../../axios/axios';
import { useLoginStore } from '../../store/zustend';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import jsPDF from 'jspdf';


const RegistrarContrato = () => {

    const { loginStore } = useLoginStore()

    const [ahorrador, setAhorrador] = useState('')
    const [tipoAhorro, setTipoAhorro] = useState('')
    const [ahorro, setAhorro] = useState(0)
    const [comienzoAhorro, setComienzoAhorro] = useState(0)
    const [ahorradores, setAhorradores] = useState([])
    const [tipoAhorros, setTipoAhorros] = useState([])
    const [comienzaContrato, setComienzaContrato] = useState([])

    const [spGuardar, setSpGuardar] = useState(false)
    const navigate = useNavigate()

    const [nombreBenef, setNombreBenef] = useState('')
    const [paternoBenef, setPaternoBenef] = useState('')
    const [maternoBenef, setMaternoBenef] = useState('')
    const [catParentesco, setCatParentesco] = useState([])
    const [parentesco, setParentesco] = useState('')
    const [beneficiarios, setBeneficiarios] = useState([])
    const [porcentajeBenef, setPorcentajeBenef] = useState(0)

    const obtenerAhorradores = async() => {


        try {
            const obtener_ahorradores = await apiSafibc('/obtener-ahorradores',{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            setAhorradores(obtener_ahorradores.data)


        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al obtener ahorradores, volver a cargar pagina por favor!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }

        
    }

    const obtenerTipoAhorros = async() => {

        try {
            const obtener_tipo_ahorros = await apiSafibc('/obtener-tipo-ahorros',{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            setTipoAhorros(obtener_tipo_ahorros.data)
        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al obtener tipo de ahorros, volver a cargar pagina por favor!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }
    }

    const obtenerParentescos = async() => {

        try {
            const obtener = await apiSafibc.get('/obtener-cat-parentescos',{
                headers:{
                    'Authorization' : `Bearer ${loginStore.token}`,
                    'Accept': 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })

            setCatParentesco(obtener.data)

        } catch (error) {
            let msg_error = ''
            if(error.response){
                if(error.response.data.mensaje){
                    msg_error = error.response.data.mensaje
                }else{
                    msg_error = 'Ocurrió un error al obtener catalogo de parentescos, volver a cargar pagina por favor!'
                }
            }
            //mensaje de toastify
            toast.error(msg_error, toast_config)
        }

        

    }

    useEffect(() => {
        obtenerAhorradores()
        obtenerTipoAhorros()
        obtenerParentescos()
    },[])

    const handleChange = (e) => {
        e.preventDefault()

        setAhorrador(e.target.value)
    }

    const handleAhorro = (e) => {
        e.preventDefault()

        setTipoAhorro(e.target.value)
    }

    const guardarContrato = () => {

        const guardar_contrato = async() => {
            

            try {
                if(!ahorrador) return toast.error('Seleccionar ahorrador es obligatorio', toast_config)
                if(!tipoAhorro) return toast.error('Seleccionar tipo de ahorro es obligatorio', toast_config)
                if(ahorro === 0) return toast.error('Agregar un monto de ahorro', toast_config)
                if(!comienzaContrato) return toast.error('Es necesario seleccionar una fecha de comienzo de contrato!', toast_config)
                if(beneficiarios.length === 0) return toast.error('Agregar beneficiarios!', toast_config)

                let porcentaje_total = 0
                beneficiarios.forEach((benef) => {
                    porcentaje_total += benef.porcentaje
                })

                if(porcentaje_total < 100) return toast.error('El porcentaje de los beneficiarios tiene que dar un total del 100%!', toast_config)

                Swal.fire({
                    title: "Esta seguro?",
                    text: "Se va a crear contrato!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, crear!"
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        setSpGuardar(true)

                        const cd = new Date()
                        const scd = `${cd.getFullYear()}-${cd.getMonth() + 1}-${cd.getDate()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`
                        
                        const contrato = {
                            id_ahorrador: ahorrador,
                            id_ahorro: tipoAhorro,
                            fecha_comienza: comienzaContrato,
                            cantidad_ahorrar: ahorro,
                            cantidad_inicial: comienzoAhorro,
                            id_estatus_contrato: 1,
                            qr: 'qr',
                            beneficiarios,
                            id_usr_registro: loginStore.jtoken.id_usuario
                        }
        
        
                        
        
                        const guardando_contrato = await apiSafibc.post('/guardar-contrato',contrato,{
                            headers:{
                                'Authorization' : `Bearer ${loginStore.token}`,
                                'Accept': 'application/x-www-form-urlencoded',
                                'Content-Type': 'application/x-www-form-urlencoded',
                            }
                        })
        
                        setSpGuardar(false)

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: guardando_contrato.data.mensaje
                        }).then(r => {
                            window.location.href = 'registrar-contrato'
                        })

                        //const htmlString = '<h1>Mi Contenido</h1><p>Este es un párrafo en mi contenido.</p>';
                        const html = atob(guardando_contrato.data)

                       //VER MANDERA DE CONVERTIR HTML A PDF DESDE AQUI, YA SE ESTA MANDANDO EL HTML EN BASE64 Y SE VUELVE A CONVERITR EN STRING
 
                        return
                        //console.log(pdfPromise)
                        /*pdfPromise
                            .then((pdfBlob) => {
                                const arrayBuffer = new Uint8Array(pdfBlob).buffer;
                                const pdfDataUri = 'data:application/pdf;base64,' + btoa(String.fromCharCode.apply(null, arrayBuffer));

                                // Abre una nueva ventana con la URL del PDF
                                window.open(pdfDataUri, '_blank');
                            })
                            .catch((error) => {
                                console.error('Error al generar el PDF:', error);
                            });*/
                          
                        //generar pdf
                        /*let byteCharacters = atob(guardando_contrato.data);
                        let byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        let byteArray = new Uint8Array(byteNumbers);

                        let file = new Blob([byteArray], { type: 'application/pdf' });
                        let fileURL = URL.createObjectURL(file);

                        window.open(fileURL, '_blank')*/

                        Swal.fire({
                            icon: 'success',
                            title: 'Enhorabuena!',
                            text: guardando_contrato.data.mensaje
                        }).then(r => {
                            //window.location.href = 'registrar-contrato'
                        })
                    }
                  }).catch((error) => {
                    console.log(error)
                    let msg_error = ''
                    if(error.response){
                        if(error.response.data.error){
                            msg_error = error.response.data.error
                        }else{
                                msg_error = 'Ocurrió un error al agregar contrato, favor de volver a intentar!'
                        }
                    }
                    setSpGuardar(false)
                    //mensaje de toastify
                    toast.error(msg_error, toast_config)
                    
                });

                
                
            } catch (error) {
                let msg_error = ''
                if(error.response){
                    if(error.response.data.mensaje){
                        msg_error = error.response.data.mensaje
                    }else{
                        msg_error = 'Ocurrió un error al guardar contrato, favor de volver a cargar!'
                    }
                }
                setSpGuardar(false)
                //mensaje de toastify
                toast.error(msg_error, toast_config)
            }

        }

        guardar_contrato()

    }

    const guardarAhorros = (e, opcion) => {

        const numero = Number(e.target.value)

        if(opcion === 1){
            setAhorro(numero)
        }else{
            setComienzoAhorro(numero)
        }

    }

    const guardarBenef = () => {

        //const nombre_completo = `${nombreBenef.trim()} ${paternoBenef.trim()} ${maternoBenef.trim()}`

        if(!nombreBenef) return toast.error('Es necesario el nombre del beneficiario!')
        if(!parentesco) return toast.error('Es necesario seleccionar parentesco!')
        if(porcentajeBenef <= 0) return toast.error('El porcentaje no puede ser 0 o menor!')

        const ya_existe = beneficiarios.some((obj) => obj.nombre === nombreBenef && obj.paterno === paternoBenef && obj.materno === maternoBenef)

        if(ya_existe) return toast.info('Este beneficiario ya esta agregado!', toast_config)

        const ya_parentesco = beneficiarios.some((obj) => obj.parentesco === parentesco)

        if(ya_parentesco) return toast.info('El tipo de beneficiario ya fue agregado!', toast_config)

        let porcentaje_total = 0

        beneficiarios.forEach(benef => {
            porcentaje_total += benef.porcentaje
        })

        if(porcentaje_total >= 100) return toast.info('El porcentaje excede el 100%, no se puede agregar beneficiario!', toast_config)

        const copia_benef = [...beneficiarios, {
            nombre: nombreBenef,
            paterno: paternoBenef ? paternoBenef : '',
            materno: maternoBenef ? maternoBenef : '',
            parentesco, 
            porcentaje : porcentajeBenef
        }]

        setBeneficiarios(copia_benef)
    }

    const eliminarBenef = (e, i) => {
        const nuevaCopia = [...beneficiarios.slice(0, i), ...beneficiarios.slice(i + 1)]
        setBeneficiarios(nuevaCopia)

    }

    return(
        <div className='container pt-5 animation'>
            <div className='row d-flex justify-content-center'>
                <div className='col-md-10'>
                    <div className='card shadow'>
                        <div className='card-body'>
                            <h3>Registrar Contrato</h3>
                            <hr />
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <FormControl variant="standard" sx={{minWidth: '100%' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Ahorrador</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={ahorrador}
                                            onChange={handleChange}
                                            label="Ahorrador"
                                        >
                                            {ahorradores && ahorradores.map(ahorrador => (
                                                <MenuItem value={ahorrador.id_ahorrador} key={ahorrador.id_ahorrador}>{`${ahorrador.nombre} ${ahorrador.paterno} ${ahorrador.materno}`}</MenuItem>
                                            ))} 
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='col-md-6 mb-3'>
                                    <FormControl variant="standard" sx={{minWidth: '100%' }}>
                                        <InputLabel id="standard-label">Tipo de Ahorro</InputLabel>
                                        <Select
                                            labelId="standard-label"
                                            id="select-standard"
                                            value={tipoAhorro}
                                            onChange={handleAhorro}
                                            label="Tipo de Ahorro"
                                        >
                                            {tipoAhorros && tipoAhorros.map(ta => (
                                                <MenuItem value={ta.id_ahorro} key={ta.id_ahorro}>{`${ta.descripcion}`}</MenuItem>
                                            ))} 
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='col-md-6 mb-3'>
                                    <TextField onChange={(e) => guardarAhorros(e,1)} value={ahorro} type='number' id="standard-basic" style={{'width' : '100%'}} label="Cantidad a Ahorrar" variant="standard" />      
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <TextField onChange={(e) => guardarAhorros(e,2)} value={comienzoAhorro} type='number' id="standard-basic" style={{'width' : '100%'}} label="Cantidad Inicial a Ahorrar" variant="standard" />      
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker value={comienzaContrato}  label='Comienzo de Contrato' variant='standard' onChange={(value) => setComienzaContrato(value)}/>
                                    </LocalizationProvider>      
                                </div>
                                <div className='col-md-12 mb-3 '>
                                    <h3>Beneficiarios:</h3>
                                    <div className='d-flex justify-content-center mb-3'>
                                        <TextField value={nombreBenef} onChange={(e) => setNombreBenef(e.target.value.toUpperCase())} className='mx-3' variant='standard' label='Nombre(s)' style={{'width' : '25%'}}/>           
                                        <TextField value={paternoBenef} onChange={(e) => setPaternoBenef(e.target.value.toUpperCase())} className='mx-3' variant='standard' label='Apellido Paterno' style={{'width' : '25%'}}/>           
                                        <TextField value={maternoBenef} onChange={(e) => setMaternoBenef(e.target.value.toUpperCase())} className='mx-3' variant='standard' label='Apellido Materno' style={{'width' : '25%'}}/>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <FormControl variant="standard" className='mx-3' sx={{minWidth: '35%' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Parentesco</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={parentesco}
                                                onChange={(e) => setParentesco(e.target.value)}
                                                label="Parentesco"
                                            >
                                                {catParentesco && catParentesco.map(parentesco => (
                                                    <MenuItem value={parentesco.id_parentesco} key={parentesco.id_parentesco}>{parentesco.parentesco}</MenuItem>
                                                ))} 
                                            </Select>
                                        </FormControl>
                                        <TextField value={porcentajeBenef} onChange={(e) => setPorcentajeBenef(e.target.value)} type='number' variant='standard' label='Porcentaje' className='mx-3' style={{'width' : '25%'}} />
                                    </div>
                                    <br />
                                    <Button variant='outlined' className='my-3' onClick={guardarBenef}>Agregar Beneficiario</Button>

                                    <div className='table-responsive'>
                                       <table className='table table-hover'>
                                          <thead className='thead-light'>
                                                <tr>
                                                    <th scope='col' className='text-center'>Nombre</th>
                                                    <th scope='col' className='text-center'>Parentesco</th>
                                                    <th scope='col' className='text-center'>Porcentaje</th>
                                                    <th scope='col' className='text-center'></th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                            {beneficiarios.length > 0 && beneficiarios.map((benef, i) => (
                                                <tr key={i}>
                                                    <td className='text-center'>{`${benef.nombre} ${benef.paterno} ${benef.materno}`}</td>
                                                    <td className='text-center'>{benef.parentesco}</td>
                                                    <td className='text-center'>{benef.porcentaje}%</td>
                                                    <td className='text-center'>
                                                        <DeleteOutlineIcon onClick={(e) => eliminarBenef(e, i)} className='text-danger' style={{'cursor' : 'pointer'}} />
                                                    </td>
                                                </tr>
                                            ))}        
                                          </tbody>
                                       </table>

                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <Button disabled={spGuardar} variant='contained' onClick={guardarContrato}>
                                        Registrar Contrato
                                        {spGuardar && <div className="spinner-border spinner-border-sm" role="status" id='spisLoad'>
                                            <span className="sr-only"></span>
                                        </div>}
                                    </Button>
                                </div>
                            </div>
                                
                            <hr />

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer 
                transition={Flip}
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"/>
        </div>
    )


}

export default RegistrarContrato