import {create} from "zustand";
import jwtDecode from "jwt-decode"

export const useLoginStore = create((set) => ({
    loginStore : {
        auth: localStorage.getItem('token') ? true : false,
        token: localStorage.getItem('token') || '',
        jtoken: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token').toString()) : '' 
    },
    guardarAuth: (loginStore) => set({loginStore}),
    limpiarAuth: () => set({ 
        auth: false,
        token: '',
        jtoken: '' })
}))