
import { Button } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useLoginStore } from "../../store/zustend"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const HeaderColaboradores = () => {

    const { guardarAuth, loginStore } = useLoginStore()

    const navigate = useNavigate()

    const cerrarSesion = () => {
        guardarAuth({
            auth: false,
            token: '', 
            jtoken: ''
        })

        localStorage.setItem('token', '')

        navigate('/iniciar-sesion')
    }

    return(
        <>
            <div className="container-fluid nav-header1">
                <div className="row">
                    <div className="col-md-3 px-5 py-2">
                        <Link to={'/home'}>
                            <img src="/img/logosafibc.png" width={'250px'} alt="" className="maxw" />
                        </Link>
                    </div>  
                </div>
            </div>
            <div className="shadow nav-header2 py-2 px-4 d-flex justify-content-end">
                <Button variant="contained" onClick={cerrarSesion}>Bienvenido {loginStore.jtoken ? loginStore.jtoken.usuario: 'Error'} <ExitToAppIcon/></Button>
            </div> 
    
        </>
        
    )
}

export default HeaderColaboradores