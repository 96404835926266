
const Header  = ({eventHandler}) => {

    const navigation =(text)=>{
        let navHeigth = document.getElementById('navbar').offsetHeight;
        return eventHandler(text, navHeigth);
    
      }

    return(
        <div className="container-fluid border-bottom shadow-lg fixed-top bg-light border-bottom" style={{'zIndex' : '99'}} id="navbar">
            <div className="container">
                <div className="row p-3">
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img src="/img/logosafibc.png" alt="" className="logo" width={'125px'} />
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center" id="menu">
                        <span className="fw-bold pmenu px-2" onClick={() => navigation('inicio')}>Inicio</span> <div className="vertical-line"></div>
                        <span className="fw-bold pmenu px-2" onClick={() => navigation('acercade')}>Acerca de</span> <div className="vertical-line"></div>
                        <span className="fw-bold pmenu px-2" onClick={() => navigation('ahorros')}>Ahorros</span> <div className="vertical-line"></div>
                        <span className="fw-bold pmenu px-2" onClick={() => navigation('contacto')}>Contacto</span>

                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Header